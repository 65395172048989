export default {
  AED: {
    thickness: 0x0c,
    lengths: {
      5: [0x99, 0x85],
      10: [0x9d, 0x89],
      20: [0x9f, 0x8b],
      50: [0xa1, 0x8d],
      100: [0xa5, 0x91],
      200: [0xa7, 0x93],
      500: [0xa9, 0x95],
      1000: [0xad, 0x99]
    },
    polymer: false
  },
  ANG: {
    thickness: 0x0c,
    lengths: {
      10: [0x98, 0x8e],
      25: [0x98, 0x8e],
      50: [0x98, 0x8e],
      100: [0x98, 0x8e]
    },
    polymer: false
  },
  AUD: {
    thickness: 0x0d,
    lengths: {
      5: [0x8c, 0x78],
      10: [0x93, 0x7f],
      20: [0x9a, 0x86],
      50: [0xa1, 0x8d],
      100: [0xa8, 0x94]
    },
    polymer: true
  },
  BGN: {
    thickness: 0x0d,
    lengths: {
      1: [0x75, 0x6b],
      2: [0x79, 0x6f],
      5: [0x7e, 0x74],
      10: [0x83, 0x79],
      20: [0x88, 0x7e],
      50: [0x8d, 0x83],
      100: [0x92, 0x88]
    },
    polymer: false
  },
  CAD: {
    thickness: 0x0d,
    lengths: {
      5: [0xa2, 0x8e],
      10: [0xa2, 0x8e],
      20: [0xa2, 0x8e],
      50: [0xa2, 0x8e],
      100: [0xa2, 0x8e]
    },
    polymer: true
  },
  CHF: {
    thickness: 0x0d,
    lengths: {
      10: [0x85, 0x71],
      20: [0x8c, 0x78],
      50: [0x93, 0x7f],
      100: [0x9a, 0x86],
      200: [0xa1, 0x8d],
      1000: [0xa8, 0x94]
    },
    polymer: false
  },
  CNY: {
    thickness: 0x0d,
    lengths: {
      1: [0x8c, 0x78],
      5: [0x91, 0x7d],
      10: [0x96, 0x82],
      20: [0x9b, 0x87],
      50: [0xa0, 0x8c],
      100: [0x91, 0xa5]
    },
    polymer: false
  },
  COP: {
    thickness: 0x0d,
    lengths: {
      2000: [0x8a, 0x76],
      5000: [0x8f, 0x7b],
      10000: [0x94, 0x80],
      20000: [0x99, 0x85],
      50000: [0x9e, 0x8a],
      100000: [0xa3, 0x8f]
    },
    polymer: false
  },
  CRC: {
    thickness: 0x0d,
    lengths: {
      1000: [0x82, 0x78],
      2000: [0x89, 0x7f],
      5000: [0x90, 0x86],
      10000: [0x97, 0x8d],
      20000: [0x9e, 0x94],
      50000: [0xaa, 0x96]
    },
    polymer: true
  },
  CZK: {
    thickness: 0x0c,
    lengths: {
      100: [0x96, 0x82],
      200: [0x9c, 0x88],
      500: [0xa2, 0x8e],
      1000: [0xa8, 0x94],
      2000: [0xae, 0x9a],
      5000: [0xb4, 0xa0]
    },
    polymer: false
  },
  EUR: {
    thickness: 0x0c,
    lengths: {
      5: [0x82, 0x6e],
      10: [0x89, 0x75],
      20: [0x8f, 0x7b],
      50: [0x96, 0x82],
      100: [0x9d, 0x89],
      200: [0xa3, 0x8f],
      500: [0xaa, 0x96]
    },
    polymer: false
  },
  GBP: {
    thickness: 0x0d,
    lengths: {
      5: [0x91, 0x7d],
      10: [0x98, 0x84],
      20: [0x95, 0x81],
      50: [0xa6, 0x92]
    },
    polymer: true
  },
  GHS: {
    thickness: 0x0c,
    lengths: {
      1: [0x93, 0x7f],
      2: [0x96, 0x82],
      5: [0x97, 0x83],
      10: [0x9b, 0x87],
      20: [0x9f, 0x8b],
      50: [0xa3, 0x8f]
    },
    polymer: false
  },
  GIP: {
    thickness: 0x0c,
    lengths: {
      5: [0x8a, 0x80],
      10: [0x92, 0x88],
      20: [0x9b, 0x91],
      50: [0xa2, 0x98],
      100: [0xa9, 0x9f]
    },
    polymer: false
  },
  GTQ: {
    thickness: 0x0c,
    lengths: {
      5: [0xa3, 0x99],
      10: [0xa1, 0x97],
      20: [0xa3, 0x99],
      50: [0xa1, 0x97],
      100: [0xa1, 0x97],
      200: [0xa1, 0x97]
    },
    polymer: false
  },
  HKD: {
    thickness: 0x0d,
    lengths: {
      10: [0x90, 0x7c],
      20: [0x99, 0x85],
      50: [0x9e, 0x8a],
      100: [0xa3, 0x8f],
      500: [0xa8, 0x94],
      1000: [0xae, 0x9a]
    },
    polymer: false
  },
  HNL: {
    thickness: 0x0c,
    lengths: {
      1: [0xa6, 0x92],
      2: [0xa6, 0x92],
      5: [0xa6, 0x92],
      10: [0xa6, 0x92],
      20: [0xa6, 0x92],
      50: [0xa6, 0x92],
      100: [0xa6, 0x92],
      200: [0xa6, 0x92],
      500: [0xa6, 0x92]
    },
    polymer: false
  },
  HRK: {
    thickness: 0x0c,
    lengths: {
      5: [0x7f, 0x75],
      10: [0x83, 0x79],
      20: [0x87, 0x7d],
      50: [0x8b, 0x81],
      100: [0x8f, 0x85],
      200: [0x93, 0x89],
      500: [0x97, 0x8d],
      1000: [0x9b, 0x91]
    },
    polymer: false
  },
  ILS: {
    thickness: 0x0d,
    lengths: {
      20: [0x8b, 0x77],
      50: [0x92, 0x7e],
      100: [0x99, 0x85],
      200: [0xa0, 0x8c]
    },
    polymer: false
  },
  JPY: {
    thickness: 0x0d,
    lengths: {
      1000: [0x99, 0x93],
      2000: [0x9c, 0x98],
      5000: [0x9d, 0x9a],
      10000: [0xa3, 0x9e]
    },
    polymer: false
  },
  KRW: {
    thickness: 0x0d,
    lengths: {
      1000: [0x92, 0x7e],
      2000: [0x96, 0x82],
      5000: [0x98, 0x84],
      10000: [0x9e, 0x8a],
      50000: [0xa4, 0x90]
    },
    polymer: false
  },
  MDL: {
    thickness: 0x0c,
    lengths: {
      1: [0x7c, 0x6e],
      5: [0x7c, 0x6e],
      10: [0x83, 0x6f],
      20: [0x83, 0x6f],
      50: [0x83, 0x6f],
      100: [0x83, 0x6f],
      200: [0x8f, 0x7b],
      500: [0x8f, 0x7b],
      1000: [0x8f, 0x7b]
    },
    polymer: false
  },
  MKD: {
    thickness: 0x0c,
    lengths: {
      10: [0x91, 0x87],
      50: [0x94, 0x8a],
      100: [0x97, 0x8d],
      200: [0x95, 0x8b],
      500: [0x9a, 0x90],
      1000: [0x9d, 0x93],
      2000: [0x9d, 0x93],
      5000: [0xa0, 0x96]
    },
    polymer: true
  },
  MXN: {
    thickness: 0x0c,
    lengths: {
      20: [0x7d, 0x73],
      50: [0x82, 0x78],
      100: [0x89, 0x7f],
      200: [0x90, 0x86],
      500: [0x97, 0x8d],
      1000: [0x9e, 0x94]
    },
    polymer: true
  },
  MYR: {
    thickness: 0x0c,
    lengths: {
      1: [0x82, 0x6e],
      5: [0x91, 0x7d],
      10: [0x96, 0x82],
      20: [0x9b, 0x87],
      50: [0x9b, 0x87],
      100: [0xa0, 0x8c]
    },
    polymer: false
  },
  NAD: {
    thickness: 0x0c,
    lengths: {
      10: [0x86, 0x7c],
      20: [0x8b, 0x81],
      50: [0x91, 0x87],
      100: [0x97, 0x8d],
      200: [0x9d, 0x93]
    },
    polymer: false
  },
  NZD: {
    thickness: 0x0c,
    lengths: {
      5: [0x8c, 0x82],
      10: [0x91, 0x87],
      20: [0x96, 0x8c],
      50: [0x9b, 0x91],
      100: [0xa0, 0x96]
    },
    polymer: true
  },
  PHP: {
    thickness: 0x0c,
    lengths: {
      20: [0xaa, 0x96],
      100: [0xaa, 0x96],
      200: [0xaa, 0x96],
      500: [0xaa, 0x96],
      1000: [0xaa, 0x96]
    },
    polymer: false
  },
  PLN: {
    thickness: 0x0c,
    lengths: {
      10: [0x7d, 0x73],
      20: [0x83, 0x79],
      50: [0x89, 0x7f],
      100: [0x8f, 0x85],
      200: [0x95, 0x8b],
      500: [0x9b, 0x91]
    },
    polymer: false
  },
  RON: {
    thickness: 0x0c,
    lengths: {
      1: [0x82, 0x6e],
      5: [0x89, 0x75],
      10: [0x8f, 0x7b],
      50: [0x96, 0x82],
      100: [0x9d, 0x89],
      200: [0xa0, 0x8c],
      500: [0xa3, 0x8f]
    },
    polymer: true
  },
  SGD: {
    thickness: 0x0c,
    lengths: {
      2: [0x88, 0x74],
      5: [0x8f, 0x7b],
      10: [0x97, 0x83],
      50: [0xa6, 0x92],
      100: [0xac, 0x98],
      1000: [0xb4, 0xa0]
    },
    polymer: false
  },
  TWD: {
    thickness: 0x0d,
    lengths: {
      100: [0x9b, 0x87],
      200: [0xa0, 0x8c],
      500: [0xa5, 0x91],
      1000: [0xaa, 0x96],
      2000: [0xaf, 0x9b]
    },
    polymer: false
  },
  USD: {
    thickness: 0x0d,
    lengths: {
      1: [0xa6, 0x92],
      2: [0xa6, 0x92],
      5: [0xa6, 0x92],
      10: [0xa6, 0x92],
      20: [0xa6, 0x92],
      50: [0xa6, 0x92],
      100: [0xa6, 0x92]
    },
    polymer: false
  },
  UYU: {
    thickness: 0x0d,
    lengths: {
      20: [0xa4, 0x9a],
      50: [0x96, 0x8c],
      100: [0xa4, 0x9a],
      200: [0xa4, 0x9a],
      500: [0xa4, 0x9a],
      1000: [0xa4, 0x9a],
      2000: [0xa4, 0x9a]
    },
    polymer: false
  },
  XCD: {
    thickness: 0x0c,
    lengths: {
      5: [0x9b, 0x87],
      10: [0x9b, 0x87],
      20: [0x9b, 0x87],
      50: [0x9b, 0x87],
      100: [0x9b, 0x87]
    },
    polymer: true
  },
  ZAR: {
    thickness: 0x0c,
    lengths: {
      10: [0x8a, 0x76],
      20: [0x90, 0x7c],
      50: [0x96, 0x82],
      100: [0x9c, 0x88],
      200: [0xa2, 0x8e]
    },
    polymer: false
  }
}
