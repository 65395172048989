const styles = {
  rightAligned: {
    marginTop: '20px',
    marginLeft: 'auto',
    marginBottom: '20px'
  },
  rightLink: {
    marginLeft: '20px'
  }
}

export { styles }
